.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.companies-slider-wrapper {
  padding: 40px 0;
  background-color: #fff; }
  .companies-slider-wrapper h3 {
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin: 0; }
  .companies-slider-wrapper .companies-slider {
    background-color: #fff; }
    .companies-slider-wrapper .companies-slider .slick-list .slick-track {
      display: flex;
      align-items: center; }
      .companies-slider-wrapper .companies-slider .slick-list .slick-track .slick-slide .slide {
        position: relative;
        text-align: center; }
        .companies-slider-wrapper .companies-slider .slick-list .slick-track .slick-slide .slide img {
          margin: auto;
          width: 95px;
          min-height: 90px;
          object-fit: contain; }
    .companies-slider-wrapper .companies-slider .slick-arrow {
      top: 68px;
      z-index: 1;
      display: block;
      height: 50px;
      width: 50px;
      padding: 0;
      fill: #c5cdd8;
      border-radius: 8px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
      -webkit-transform: translateY(-50%) rotate(0deg);
      -ms-transform: translateY(-50%) rotate(0deg);
      -moz-transform: translateY(-50%) rotate(0deg);
      color: #bcbcbf; }
      .companies-slider-wrapper .companies-slider .slick-arrow polyline {
        color: #BCBCBF;
        fill: transparent;
        transform: scale(0.7) translate(25%, 25%); }
      .companies-slider-wrapper .companies-slider .slick-arrow.slick-prev {
        left: -25px;
        transform: translateY(-50%) rotate(0deg); }
      .companies-slider-wrapper .companies-slider .slick-arrow.slick-next {
        right: -25px; }
      @media only screen and (max-width: 575px) {
        .companies-slider-wrapper .companies-slider .slick-arrow {
          height: 40px;
          width: 40px;
          border-radius: 5px; } }
    @media only screen and (max-width: 575px) {
      .companies-slider-wrapper .companies-slider {
        margin: 0px 30px; } }
