.welcome_popup_container .modal-content {
  border: none; }
  .welcome_popup_container .modal-content .login-container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .welcome_popup_container .modal-content .login-form-wrap {
    width: 600px;
    text-align: center; }
    .welcome_popup_container .modal-content .login-form-wrap .login-form {
      padding: 25px;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 0 auto;
      background: #fff; }
      .welcome_popup_container .modal-content .login-form-wrap .login-form .form-group-wrap {
        width: 100%;
        flex: 1;
        display: block !important; }
        .welcome_popup_container .modal-content .login-form-wrap .login-form .form-group-wrap .form-group {
          margin-bottom: 10px; }
          .welcome_popup_container .modal-content .login-form-wrap .login-form .form-group-wrap .form-group label {
            color: #000;
            font-size: 14px;
            line-height: 1.3rem; }
          .welcome_popup_container .modal-content .login-form-wrap .login-form .form-group-wrap .form-group .form-control {
            border: 1px solid #ccc;
            padding: 0;
            height: 50px;
            text-align: center; }
    @media only screen and (max-width: 767px) {
      .welcome_popup_container .modal-content .login-form-wrap {
        width: 100%; } }
