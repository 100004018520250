.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.share-modal {
  max-width: 620px !important; }
  .share-modal .modal-content {
    border-radius: 16px;
    margin: 0 20px;
    width: calc(100% - 40px);
    position: relative; }
    .share-modal .modal-content > svg {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px; }
      .share-modal .modal-content > svg path {
        fill: #767a92; }
    .share-modal .modal-content .body {
      overflow: visible;
      padding: 30px;
      overflow: visible;
      border-radius: 6px;
      background-color: #FFFFFF;
      box-shadow: 0 0 12px 0 rgba(63, 78, 90, 0.11); }
      .share-modal .modal-content .body .attach-vacancy-wrapper .form-group label {
        margin: 0;
        position: relative;
        top: 0px;
        left: 10px;
        display: inline-block;
        width: calc(100% - 20px);
        color: #222222;
        font-weight: 400; }
      .share-modal .modal-content .body .attach-vacancy-wrapper .form-group input {
        -webkit-appearance: checkbox !important;
        display: inline-block;
        width: unset; }
      .share-modal .modal-content .body .attach-vacancy-wrapper .vacancy-picker {
        position: relative;
        margin-bottom: 16px; }
        .share-modal .modal-content .body .attach-vacancy-wrapper .vacancy-picker > div {
          position: relative !important;
          display: block !important; }
          .share-modal .modal-content .body .attach-vacancy-wrapper .vacancy-picker > div > div {
            position: absolute !important;
            width: 100% !important;
            top: 34px !important;
            left: 0 !important;
            max-height: 200px !important; }
            .share-modal .modal-content .body .attach-vacancy-wrapper .vacancy-picker > div > div > div {
              padding: 4px 12px; }
          .share-modal .modal-content .body .attach-vacancy-wrapper .vacancy-picker > div input {
            width: 100%;
            padding: 12px 20px;
            border: 0 !important;
            background-color: #f0f2f5;
            border-radius: 52px; }
      .share-modal .modal-content .body h2 {
        text-transform: capitalize;
        color: #222222;
        font-size: 21px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        position: relative;
        padding-bottom: 14px; }
        .share-modal .modal-content .body h2:after {
          position: absolute;
          content: "";
          height: 4px;
          width: 50px;
          border-radius: 2.4px;
          background-color: #45F6A6;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%); }
      .share-modal .modal-content .body .platforms {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px; }
        .share-modal .modal-content .body .platforms .platform {
          color: white;
          text-align: center;
          cursor: pointer; }
          .share-modal .modal-content .body .platforms .platform span {
            display: block;
            color: #555555;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            margin-top: 15px; }
            @media only screen and (max-width: 575px) {
              .share-modal .modal-content .body .platforms .platform span {
                margin-bottom: 10px; } }
          .share-modal .modal-content .body .platforms .platform img {
            height: 38px;
            width: 38px; }
          @media only screen and (max-width: 575px) {
            .share-modal .modal-content .body .platforms .platform {
              width: 30%;
              margin-bottom: 15px; } }
        .share-modal .modal-content .body .platforms > div:empty {
          display: none; }
    .share-modal .modal-content .email-share {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s;
      text-align: center;
      margin-top: 10px; }
      .share-modal .modal-content .email-share .form-group {
        margin-bottom: 16px; }
        .share-modal .modal-content .email-share .form-group input {
          background-color: #f0f2f5;
          padding: 12px 20px;
          display: block;
          width: 100%;
          border: 0 !important;
          border-radius: 52px; }
        .share-modal .modal-content .email-share .form-group label {
          display: none;
          margin-top: 20px; }
        .share-modal .modal-content .email-share .form-group button {
          background-color: #7d7d7d;
          border: none;
          padding: 10px 20px;
          color: #fff;
          border-radius: 52px;
          font-size: 14px; }
      .share-modal .modal-content .email-share .status {
        margin-bottom: 12px;
        font-size: 15px !important;
        font-weight: 600; }
    .share-modal .modal-content textarea {
      width: 0;
      height: 0;
      position: absolute;
      top: -10000px; }
  .share-modal[data-email-open="true"] .email-share {
    max-height: 500px; }

.modal-backdrop.show {
  opacity: .8; }
