.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.job-for-you-preview-card {
  /*padding: 36px 36px 124px 36px;*/
  padding: 20px;
  padding-bottom: 0;
  background-color: white;
  width: 100%;
  display: inline-block;
  margin-right: 0px;
  margin-bottom: 20px;
  border-radius: 12px;
  min-height: 120px;
  border-radius: 8px;
  position: relative; }
  @media only screen and (max-width: 575px) {
    .job-for-you-preview-card .profile-container {
      flex-direction: column; } }
  .job-for-you-preview-card .content-wrap {
    flex: 1; }
  .job-for-you-preview-card .profile-img {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    margin-right: 30px; }
    .job-for-you-preview-card .profile-img img {
      width: 40px;
      height: 40px; }
    @media only screen and (max-width: 575px) {
      .job-for-you-preview-card .profile-img {
        margin: 0 auto 30px; } }
  .job-for-you-preview-card .last-open {
    color: #767a92;
    margin: 0;
    padding: 0px 0 16px 2px;
    font-weight: 700;
    text-transform: uppercase; }
  .job-for-you-preview-card:nth-child(even) {
    margin-right: 0; }
  .job-for-you-preview-card .content-wrap h2 {
    color: #2C2C2C;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.14px;
    line-height: 26px;
    text-align: left;
    width: calc(100% - 116px);
    text-transform: capitalize;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative; }
    .job-for-you-preview-card .content-wrap h2:after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      height: 4px;
      width: 60px;
      border-radius: 2px;
      background-color: #45F6A6; }
    @media only screen and (max-width: 767px) {
      .job-for-you-preview-card .content-wrap h2 {
        width: 90%;
        font-size: 16px;
        line-height: 24px; } }
  @media only screen and (max-width: 991px) {
    .job-for-you-preview-card .content-wrap .tags-container {
      overflow: scroll;
      height: 150px; } }
  .job-for-you-preview-card p {
    color: #656363;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: 400;
    max-width: 800px;
    margin-bottom: 10px; }
  .job-for-you-preview-card .tags-container {
    max-height: 105px;
    overflow-y: auto;
    margin-top: 15px;
    min-height: 105px;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .job-for-you-preview-card .tags-container .tag {
      border-radius: 20px;
      background-color: #F5F5F5;
      padding: 12px 12px;
      height: 40px;
      color: #222222;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.1px;
      line-height: 16px;
      text-align: center; }
    .job-for-you-preview-card .tags-container::-webkit-scrollbar {
      width: 4px;
      border-radius: 10px; }
    .job-for-you-preview-card .tags-container::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px; }
    .job-for-you-preview-card .tags-container::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px; }
    .job-for-you-preview-card .tags-container::-webkit-scrollbar-thumb:hover {
      background: #555; }
  .job-for-you-preview-card .cta {
    border-radius: 2px 2px 4px 4px;
    background-color: #46f6a71a;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
    margin: 0px -20px; }
    .job-for-you-preview-card .cta:empty {
      min-height: 44px; }
    .job-for-you-preview-card .cta .apply-for-job-btn {
      border: 1px solid #45F6A6;
      border-radius: 45px;
      color: #45F6A6;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      padding: 12px 10px;
      line-height: 15px;
      background: transparent;
      min-width: 110px; }
    .job-for-you-preview-card .cta .vacancy-emojis {
      overflow: hidden;
      white-space: nowrap;
      margin: auto;
      display: flex;
      max-width: 100%;
      width: 100%;
      flex-direction: unset !important;
      justify-content: center; }
      .job-for-you-preview-card .cta .vacancy-emojis .vacancy-emojis-wrap img {
        margin-right: 0; }
      .job-for-you-preview-card .cta .vacancy-emojis .vacancy-emojis-wrap span {
        display: none; }
      .job-for-you-preview-card .cta .vacancy-emojis > div {
        margin: 0px 10px; }
      .job-for-you-preview-card .cta .vacancy-emojis svg {
        height: 36px;
        width: auto;
        margin-left: 0px; }
      @media only screen and (max-width: 767px) {
        .job-for-you-preview-card .cta .vacancy-emojis {
          overflow: unset;
          white-space: pre-wrap;
          margin-bottom: 10px; } }
    @media only screen and (max-width: 767px) {
      .job-for-you-preview-card .cta {
        flex-direction: column; } }
  .job-for-you-preview-card .share-btn,
  .job-for-you-preview-card .favorite-btn {
    padding: 0;
    border: 0;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px 20px 0 0; }
    .job-for-you-preview-card .share-btn.favorite-btn,
    .job-for-you-preview-card .favorite-btn.favorite-btn {
      margin-left: 12px; }
      .job-for-you-preview-card .share-btn.favorite-btn svg,
      .job-for-you-preview-card .favorite-btn.favorite-btn svg {
        width: 22px;
        height: auto;
        fill: #BCBCBF; }
      .job-for-you-preview-card .share-btn.favorite-btn:hover svg,
      .job-for-you-preview-card .favorite-btn.favorite-btn:hover svg {
        fill: #45F6A6; }
      .job-for-you-preview-card .share-btn.favorite-btn[data-active='true'] svg,
      .job-for-you-preview-card .favorite-btn.favorite-btn[data-active='true'] svg {
        fill: #45F6A6; }
    .job-for-you-preview-card .share-btn.share-btn,
    .job-for-you-preview-card .favorite-btn.share-btn {
      right: 32px; }
      .job-for-you-preview-card .share-btn.share-btn svg,
      .job-for-you-preview-card .favorite-btn.share-btn svg {
        width: 20px;
        height: auto;
        position: relative;
        top: -1px; }
        .job-for-you-preview-card .share-btn.share-btn svg path,
        .job-for-you-preview-card .favorite-btn.share-btn svg path {
          fill: #BCBCBF; }
      .job-for-you-preview-card .share-btn.share-btn span,
      .job-for-you-preview-card .favorite-btn.share-btn span {
        color: #BCBCBF;
        font-weight: 600;
        font-size: 0.95em;
        margin-left: 10px; }
  .job-for-you-preview-card .share-btn {
    border-right: 1px solid #97979726;
    height: 30px;
    padding-right: 10px; }
  .job-for-you-preview-card .favorite-btn {
    padding-left: 10px; }
  .job-for-you-preview-card[data-loading="true"] .cta > span > span {
    height: 30px; }
  .job-for-you-preview-card[data-loading="true"] h2 {
    max-width: calc(100% - 148px); }
    .job-for-you-preview-card[data-loading="true"] h2 > span > span {
      width: 100% !important; }
  .job-for-you-preview-card[data-loading="true"] .share-btn > span > span,
  .job-for-you-preview-card[data-loading="true"] .favorite-btn > span > span {
    height: 24px;
    margin-top: 10px; }
  .job-for-you-preview-card[data-loading="true"] .share-btn.share-btn > span > span,
  .job-for-you-preview-card[data-loading="true"] .favorite-btn.share-btn > span > span {
    margin-right: 8px; }
  .job-for-you-preview-card[data-favorite-show="false"] .share-btn {
    right: 0; }
  .job-for-you-preview-card:hover {
    text-decoration: none; }
  .job-for-you-preview-card[data-potnoodle="true"] .tag {
    background-color: #ffd956; }
  .job-for-you-preview-card[data-potnoodle="true"] .apply-for-job-btn {
    background-color: #009639 !important; }
  .job-for-you-preview-card[data-potnoodle="true"] .potnoodle-badge {
    width: 87px;
    position: absolute;
    bottom: 20px;
    border-radius: 100%;
    right: 20px; }
    @media only screena and (max-width: 480px) {
      .job-for-you-preview-card[data-potnoodle="true"] .potnoodle-badge {
        width: 65px; } }
  .job-for-you-preview-card[data-potnoodle="true"] .cta .apply-for-job-btn {
    margin-right: 100px; }
    @media only screen and (max-width: 767px) {
      .job-for-you-preview-card[data-potnoodle="true"] .cta .apply-for-job-btn {
        margin: 0; } }
  .job-for-you-preview-card[data-potnoodle="false"] .apply-for-job-btn {
    background-color: transparent !important; }
  .job-for-you-preview-card[data-potnoodle="false"] img.potnoodle-badge {
    display: none !important; }
  @media (max-width: 992px) {
    .job-for-you-preview-card .share-btn {
      display: none; }
    .job-for-you-preview-card .potnoodle-badge {
      bottom: 20px !important;
      right: 20px !important; } }
  @media (max-width: 760px) {
    .job-for-you-preview-card {
      width: 100%;
      margin: 0 auto 24px auto !important;
      display: block; } }
  @media only screen and (max-width: 767px) {
    .job-for-you-preview-card .potnoodle-badge {
      bottom: 10px !important; } }
  @media only screen and (max-width: 575px) {
    .job-for-you-preview-card {
      width: 90%; } }
  .job-for-you-preview-card .add_inner_wrap {
    min-height: 270px; }
    .job-for-you-preview-card .add_inner_wrap .service_based_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px; }
      .job-for-you-preview-card .add_inner_wrap .service_based_wrap label {
        font-size: 12px;
        font-weight: 500;
        color: #f2afb0; }
      .job-for-you-preview-card .add_inner_wrap .service_based_wrap .time_duratin {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.5); }
    .job-for-you-preview-card .add_inner_wrap .sonicDescription {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }

.search-job-list .job-for-you-preview-card .cta {
  padding-left: 165px; }
  @media only screen and (max-width: 575px) {
    .search-job-list .job-for-you-preview-card .cta {
      padding-left: unset;
      padding: 10px 20px; } }

.search-job-list .job-for-you-preview-card[data-potnoodle="false"] .potnoodle-badge {
  display: none; }

.Job-for-you {
  background-color: #F5F5F5;
  padding: 40px 0; }
  .Job-for-you h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px; }
  .Job-for-you h5 {
    text-align: center;
    color: #333333;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 30px; }
  .Job-for-you .view-more-btn-section {
    padding: 50px 0; }
    .Job-for-you .view-more-btn-section .view-more-btn {
      border: 1px solid #45F6A6;
      border-radius: 45px;
      color: #45F6A6;
      background-color: #fff !important;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      padding: 17px 35px;
      line-height: 15px;
      min-width: 110px;
      text-decoration: none;
      cursor: pointer; }
  .Job-for-you .job-for-you-preview-card .apply-for-job-btn {
    border: 1px solid #45F6A6;
    border-radius: 45px;
    color: #222;
    background-color: #45F6A6 !important;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 12px 10px;
    line-height: 15px;
    min-width: 110px; }
  .Job-for-you .job-for-you-preview-card h2 {
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.14px;
    line-height: 26px;
    text-align: left;
    text-transform: capitalize;
    overflow: hidden;
    padding-bottom: 15px;
    position: relative;
    margin: 15px 0; }
    .Job-for-you .job-for-you-preview-card h2:after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      height: 4px;
      width: 60px;
      border-radius: 2px;
      background-color: #45F6A6; }
    @media only screen and (max-width: 767px) {
      .Job-for-you .job-for-you-preview-card h2 {
        width: 90%;
        font-size: 16px;
        line-height: 24px; } }
