@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.slick-prev, .slick-next {
  font-size: 15px !important; }

.slick-prev:before, .slick-next:before {
  content: '' !important; }

.slick-arrow {
  background-color: #c5cdd8;
  padding: 4px;
  width: 28px;
  height: 28px;
  border-radius: 6px; }
  .slick-arrow:hover {
    background-color: #9ca9bb; }
