.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

#root > div[data-ie='true'] .global-search .job-search-container .visible {
  min-height: 63px; }
  #root > div[data-ie='true'] .global-search .job-search-container .visible .search-input svg {
    max-height: 30px; }
  #root > div[data-ie='true'] .global-search .job-search-container .visible .search-input .ReactTags__tag, #root > div[data-ie='true'] .global-search .job-search-container .visible .search-input .ReactTags__tagInputField {
    padding: 7px 20px; }

#root > div[data-ie='true'] .footer input {
  height: 57px;
  padding: 16px 72px 16px 32px; }

#root > div[data-ie='true'] .search-list .button-group-alt button {
  float: left;
  height: 28px; }

#root > div[data-ie='true'] .button-group button svg {
  top: 1px; }

#root > div[data-ie='true'] .single-pagination button {
  height: 38px; }

#root > div[data-ie='true'] .faq-container .title svg {
  margin: 0 18px; }

#root > div[data-ie='true'] .candidate-preview-card button {
  height: 38px; }

#root > div[data-ie='true'] .candidate-preview-card .button-group button.btn-secondary {
  max-width: 34px; }

#root > div[data-ie='true'] .vacancy-preview-card .share-btn svg, #root > div[data-ie='true'] .vacancy-preview-card .favorite-btn svg {
  max-height: 24px; }

#root > div[data-ie='true'] .navbar ul li a {
  height: 40px; }

#root > div[data-ie='true'] .blog-post label {
  height: 24px; }

#root > div[data-ie='true'] .service-stacks .steps a svg {
  max-height: 16px; }

#root > div[data-ie='true'] .latest-wrapper .share-btn svg {
  max-height: 24px; }

#root > div[data-ie='true'] .faq .title svg {
  max-width: 32px;
  margin-top: 16px; }

#root > div[data-ie='true'] .vacancy-details .vacancy-emojis svg, #root > div[data-ie='true'] .vacancy-preview-card .vacancy-emojis svg {
  width: 44px;
  height: 36px; }

#root > div[data-ie='true'] .tags-container .tag svg {
  height: 8px; }

#root > div[data-ie='true'] .toast {
  padding-top: 13px !important; }

#root > div[data-ie='true'] .form-group:not(.main-input-field) input {
  padding: 6px 18px; }

#root > div[data-ie='true'] .pagination-wrapper ul.pagination li a svg {
  height: 20px !important;
  width: 20px !important;
  display: block !important;
  padding: 0 !important;
  left: 8px !important; }

#root > div[data-ie='true'] .pagination-wrapper ul.pagination li:nth-child(1) a, #root > div[data-ie='true'] .pagination-wrapper ul.pagination li:nth-child(2) a, #root > div[data-ie='true'] .pagination-wrapper ul.pagination li:nth-child(3) a, #root > div[data-ie='true'] .pagination-wrapper ul.pagination li:nth-child(4) a, #root > div[data-ie='true'] .pagination-wrapper ul.pagination li:nth-child(5) a, #root > div[data-ie='true'] .pagination-wrapper ul.pagination li:nth-child(6) a {
  top: 8px !important; }

#root > div[data-ie='true'] section[data-section="job-applications"] hr {
  width: 100% !important; }

#root > div[data-ie='true'] section[data-section="job-applications"] .search-bar svg {
  height: 14px !important; }

#root > div[data-ie='true'] section[data-section="job-applications"] .column svg {
  height: 12px !important; }

#root > div[data-ie='true'] .business-dashboard .confirmation-input input {
  height: 48px !important; }

#root > div[data-ie='true'] .perks-picker > div {
  display: flex;
  flex-wrap: wrap; }
  #root > div[data-ie='true'] .perks-picker > div .perk {
    flex-basis: calc(20% - 54px) !important;
    min-height: 152px !important;
    margin: 12px !important; }
    #root > div[data-ie='true'] .perks-picker > div .perk svg:first-child {
      height: 60px !important; }
    #root > div[data-ie='true'] .perks-picker > div .perk svg:last-child {
      height: 20px !important; }

#root > div[data-ie='true'] .form-group label {
  color: #767a92 !important; }

#root > div[data-ie='true'] .job-view .main-input-field[data-input="id"] {
  float: right !important; }

#root > div[data-ie='true'] .job-view .tag-editor-container .search-icon {
  height: 26px !important; }

#root > div[data-ie='true'] .job-view .new-applications section {
  display: flex !important;
  flex-wrap: wrap !important; }
  #root > div[data-ie='true'] .job-view .new-applications section > a {
    flex-basis: calc(20% - 84px) !important;
    margin: 12px !important;
    min-height: 192px !important; }

#root > div[data-ie='true'] .job-view .applications-sections .wide-container > div section {
  display: block !important;
  clear: both; }
  #root > div[data-ie='true'] .job-view .applications-sections .wide-container > div section > a, #root > div[data-ie='true'] .job-view .applications-sections .wide-container > div section > .placeholder {
    width: calc(33.3333% - 16px) !important;
    margin: 8px !important;
    display: inline-block !important;
    float: left; }

#root > div[data-ie='true'] .job-view .graduate-thumbnail button svg {
  height: 16px !important; }

#root > div[data-ie='true'] .clearfix-ie::after {
  content: "";
  clear: both;
  display: table; }

@media (min-width: 1000px) {
  .login-modal .modal-content .body, .share-modal .modal-content .body {
    overflow: hidden !important; } }

#root > div[data-ios='true'] .platform.copy {
  display: none !important; }
