.home_new_slider .new_slider_wrap {
  display: flex;
  align-items: center;
  max-width: 1460px;
  margin: 0 auto;
  min-height: 680px; }
  .home_new_slider .new_slider_wrap .banner_content_wrap {
    flex: 2; }
    .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
      font-size: 5rem;
      margin-bottom: 20px;
      line-height: 1.1; }
      .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 500; }
      @media only screen and (max-width: 1440px) {
        .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
          font-size: 4rem; }
          .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
            font-size: 1.5rem; } }
      @media only screen and (max-width: 1199px) {
        .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
          font-size: 3.2rem; }
          .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
            font-size: 1.2rem; } }
      @media only screen and (max-width: 991px) {
        .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
          font-size: 2rem;
          text-align: center; }
          .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
            font-size: 1rem;
            margin: 0;
            text-align: center;
            line-height: 1.4rem; }
            .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p br {
              display: none; } }
    @media only screen and (max-width: 991px) {
      .home_new_slider .new_slider_wrap .banner_content_wrap {
        text-align: center; } }
  .home_new_slider .new_slider_wrap figure {
    flex: 1; }
    .home_new_slider .new_slider_wrap figure svg {
      cursor: auto; }
    @media only screen and (max-width: 575px) {
      .home_new_slider .new_slider_wrap figure div {
        width: auto !important;
        height: auto !important; } }
  @media only screen and (max-width: 1600px) {
    .home_new_slider .new_slider_wrap {
      max-width: 90%; } }
  @media only screen and (max-width: 991px) {
    .home_new_slider .new_slider_wrap {
      flex-direction: column-reverse;
      padding: 30px 0;
      min-height: 700px; } }

.home_page_new .sustainability-wrapper {
  background: #EFEFEF; }

.gradbay_can_help {
  padding: 3rem 0;
  background: #c3ffff; }
  .gradbay_can_help .section_heading {
    text-align: center;
    color: #333333;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 25px; }
  .gradbay_can_help .can_help_inner > div {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px; }
    .gradbay_can_help .can_help_inner > div figure {
      margin: 0;
      padding: 0;
      margin-right: 15px; }
      .gradbay_can_help .can_help_inner > div figure img {
        width: 60px;
        height: 60px;
        object-fit: contain; }
        @media only screen and (max-width: 640px) {
          .gradbay_can_help .can_help_inner > div figure img {
            width: 40px;
            height: 40px; } }
    .gradbay_can_help .can_help_inner > div p {
      font-size: 1rem;
      line-height: 1.4rem;
      color: #222222;
      font-weight: 500;
      margin-bottom: 0; }
    @media only screen and (max-width: 991px) {
      .gradbay_can_help .can_help_inner > div {
        align-items: center; }
        .gradbay_can_help .can_help_inner > div p {
          font-size: 0.875rem;
          line-height: 1.3rem; } }

.home_new_slider_wrapper .home_new_slider {
  height: 100%; }

.home_new_slider_wrapper .banner_slide_btn {
  padding: 20px 20px;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #000;
  background: #45f7a6;
  border-radius: 50px;
  outline: none;
  box-shadow: none;
  display: inline-block;
  margin-top: 25px;
  text-align: center;
  min-width: 240px;
  letter-spacing: 1px; }
  @media only screen and (max-width: 640px) {
    .home_new_slider_wrapper .banner_slide_btn {
      min-width: 200px;
      padding: 15px 16px;
      font-size: 1rem;
      line-height: 1.3rem; } }

.home_new_slider_wrapper .first_slide {
  background: #c3ffff; }
