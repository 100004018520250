.home-job-feed-wrapper .jobtype-page {
  display: block;
  background-color: #EFEFEF;
  padding: 40px 0; }
  .home-job-feed-wrapper .jobtype-page h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px; }
  .home-job-feed-wrapper .jobtype-page .category-card {
    cursor: pointer; }
  .home-job-feed-wrapper .jobtype-page .reviews-wrapper {
    text-align: center; }
    .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-slide > div {
      margin: 0 15px; }
    .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-arrow {
      display: block;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #FFFFFF;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      color: #bcbcbf;
      margin-top: -25px;
      z-index: 999; }
      .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-arrow polyline {
        color: #979797;
        transform: scale(0.7) translate(25%, 25%); }
      .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-arrow.slick-prev {
        left: -10px; }
      .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-arrow.slick-next {
        right: -10px; }
    .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-dots {
      bottom: -60px;
      z-index: 999; }
      .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-dots li button {
        height: 10px;
        width: 10px;
        background-color: #2C2E36;
        border-radius: 50%; }
        .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-dots li button:before {
          display: none; }
      .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-dots li.slick-active button {
        height: 23px;
        width: 23px;
        background-color: #45F6A6; }
        .home-job-feed-wrapper .jobtype-page .reviews-wrapper .slick-dots li.slick-active button:before {
          display: none; }
    .home-job-feed-wrapper .jobtype-page .reviews-wrapper h3 {
      font-size: 16px;
      font-weight: 700;
      padding-top: 20px; }
    .home-job-feed-wrapper .jobtype-page .reviews-wrapper img {
      width: 100%;
      border-radius: 10px;
      height: 260px;
      object-fit: cover;
      background: #bd99ff57; }
    .home-job-feed-wrapper .jobtype-page .reviews-wrapper .review-contain figure {
      margin: 0;
      height: 260px;
      background: #bd99ff57;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px; }
      .home-job-feed-wrapper .jobtype-page .reviews-wrapper .review-contain figure img {
        object-fit: contain;
        height: 200px;
        background: transparent; }
        @media only screen and (max-width: 767px) {
          .home-job-feed-wrapper .jobtype-page .reviews-wrapper .review-contain figure img {
            height: 180px;
            background: transparent; } }

.home-job-feed-wrapper .home-feed-wrapper {
  padding: 40px 0; }
