.modal .close_btn_right {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  z-index: 991; }
  .modal .close_btn_right svg {
    width: 25px;
    height: 25px; }

.modal .modal-dialog {
  transform: translate(0, -50%) !important;
  top: 50% !important;
  margin: 0 auto !important; }

.modal .custom_pricing_modal {
  display: flex;
  align-items: center; }
  .modal .custom_pricing_modal .modal-content .close_btn_top {
    background: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    background: white;
    position: absolute;
    right: 15px;
    top: 15px; }
    .modal .custom_pricing_modal .modal-content .close_btn_top:before, .modal .custom_pricing_modal .modal-content .close_btn_top:after {
      width: 1.5px;
      height: 20px;
      background: rgba(0, 0, 0, 0.4);
      content: '';
      top: calc(50% - 10px);
      left: calc(50% - 1px);
      position: absolute;
      transform: rotate(45deg);
      pointer-events: none; }
    .modal .custom_pricing_modal .modal-content .close_btn_top:after {
      transform: rotate(-45deg); }
    @media only screen and (max-width: 767px) {
      .modal .custom_pricing_modal .modal-content .close_btn_top {
        top: 15px; } }
  .modal .custom_pricing_modal .modal-content .body {
    margin: auto;
    border-radius: 0; }
    .modal .custom_pricing_modal .modal-content .body .top-check-icon {
      width: 90px;
      height: 90px;
      margin: 0px auto 30px;
      border: 8px solid #45f6a6;
      border-radius: 50%;
      position: relative; }
      .modal .custom_pricing_modal .modal-content .body .top-check-icon:before {
        content: "";
        height: 30px;
        width: 45px;
        border-left: 8px solid #45f6a6;
        border-bottom: 8px solid #45f6a6;
        position: absolute;
        top: calc(50% - 6px);
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg); }
    .modal .custom_pricing_modal .modal-content .body h6 {
      margin-bottom: 30px;
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8); }
    .modal .custom_pricing_modal .modal-content .body p {
      font-size: 12px;
      margin-bottom: 8px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7); }
      .modal .custom_pricing_modal .modal-content .body p span {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9); }
    .modal .custom_pricing_modal .modal-content .body .okay_btn {
      margin-top: 20px; }
