.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.form-group {
  margin-bottom: 24px; }
  .form-group label {
    color: white;
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 12px; }
  .form-group input {
    border-radius: 52px;
    padding: 28px 28px;
    border: none; }
  .form-group:last-of-type {
    margin-bottom: 0px; }

.submit-btn {
  background-color: #45F6A6;
  color: black;
  border: none;
  padding: 8px 20px;
  border-radius: 52px;
  width: 120px; }
  .submit-btn[data-enabled='false'] {
    opacity: 0.6; }
  .submit-btn:hover {
    background-color: #0be382;
    color: black;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.terms {
  color: white;
  margin-bottom: 20px;
  margin-top: 0px; }
  .terms .control input:checked ~ .control__indicator {
    background-color: #0dd575; }
  .terms label {
    padding: 9px 0 9px 60px; }
    .terms label .control__indicator {
      width: 20px;
      height: 20px;
      background-color: #651ba0;
      left: 4px;
      border-radius: 3px; }

.type-picker {
  margin-bottom: 24px;
  margin-top: 8px; }
  .type-picker span {
    color: white;
    font-weight: 600; }
  .type-picker .dropdown {
    margin-left: 12px; }

.status-msg {
  color: #db3951;
  margin-left: 4px;
  font-weight: 700; }
  .status-msg.success {
    color: #45F6A6; }
