.vacancy-emojis > div {
  display: inline-block; }

.vacancy-emojis .vacancy-emojis-wrap {
  color: #222222;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 25px; }
  .vacancy-emojis .vacancy-emojis-wrap img {
    margin-right: 20px;
    width: 24px;
    height: 24px; }
  .vacancy-emojis .vacancy-emojis-wrap svg {
    display: none; }
