.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.dropdown {
  display: inline-block;
  font-size: 13pt; }
  .dropdown.white button {
    background-color: white;
    border-color: white;
    color: #333;
    font-weight: 500; }
  .dropdown a.dropdown-item {
    text-align: center;
    padding: 6px 24px;
    font-weight: 500; }
  .dropdown[data-style="2"] .dropdown-toggle {
    background-color: white !important;
    color: #333 !important;
    border: none !important;
    box-shadow: none !important; }
  .dropdown[data-style="2"] .dropdown-menu {
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border: none;
    padding: 24px 32px;
    left: calc(-50% + 12px) !important;
    min-width: 250px; }
    .dropdown[data-style="2"] .dropdown-menu button {
      padding: 4px 0;
      border-bottom: 1px solid #c5cdd8;
      color: #767a92;
      font-size: 1em; }
      .dropdown[data-style="2"] .dropdown-menu button:last-child {
        border-bottom: 0; }
  .dropdown[data-style="3"] .dropdown-toggle {
    background-color: #45F6A6 !important;
    color: #333 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 52px;
    padding: 4px 16px;
    font-size: 0.8em; }
  .dropdown[data-style="3"] .dropdown-menu {
    left: calc(-50% - 85px) !important;
    min-width: 140px !important;
    transform: translate3d(0px, 36px, 0px) !important;
    color: black !important;
    background-color: #45F6A6 !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
    border: none !important;
    padding-top: 4px;
    padding-bottom: 4px;
    overflow: hidden; }
    .dropdown[data-style="3"] .dropdown-menu button {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: transparent; }
      .dropdown[data-style="3"] .dropdown-menu button:last-child {
        border: none; }
  .dropdown[data-style="4"] {
    width: 100%; }
    .dropdown[data-style="4"] > button {
      width: 100%;
      text-align: left; }
      .dropdown[data-style="4"] > button:after {
        float: right;
        position: relative;
        top: 9px; }
    .dropdown[data-style="4"] > div {
      width: 100%; }
