.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.spinner-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  display: none;
  /*height: 100vh;*/
  width: 100vw;
  background: rgba(255, 255, 255, 0.8);
  display: none; }
  .spinner-container .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px; }
    .spinner-container .spinner .path {
      stroke: #651ba0;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite; }

@keyframes rotate {
  100% {
    transform: rotate(360deg) translate(-50%, -50%); } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }
  .spinner-container[data-show="true"] {
    display: inline-block; }
