.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.tags-container {
  display: inline-block; }
  .tags-container .tag {
    padding: 12px 24px;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
    color: #222222;
    font-size: 13px;
    letter-spacing: -0.1px;
    line-height: 16px;
    border-radius: 20px !important;
    background-color: #F5F5F5 !important; }
    .tags-container .tag span {
      font-weight: 500; }
    .tags-container .tag svg {
      width: 8px;
      margin-left: 8px;
      position: relative;
      top: -1px; }
  .tags-container .selected {
    background-color: #45F6A6 !important; }
  .tags-container > span:not(.tag) {
    margin-right: 12px; }
  .tags-container.groupped section:not(:last-child) .category-tags {
    border-bottom: 1px solid #E4E0E0; }
  .tags-container.groupped section .category-name {
    flex-basis: 112px;
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.14px;
    line-height: 22px;
    margin-bottom: 15px;
    margin-top: 20px; }
  .tags-container.groupped section .category-tags {
    flex-basis: calc(100% - 112px);
    min-height: 38px;
    padding-bottom: 10px; }
    .tags-container.groupped section .category-tags button {
      color: #45F6A6;
      padding: 0;
      background-color: #fff;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 18px; }
  .tags-container.groupped section .category-tags:not(:last-child) {
    border-bottom: 1px solid #E4E0E0; }
  .tags-container.groupped section .category-tags {
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap; }
  .tags-container.groupped[data-show-all="true"] .category-tags {
    white-space: normal; }
  .tags-container.groupped button {
    margin: auto;
    background-color: #2A4673;
    color: white;
    padding: 4px 26px 4px 20px;
    display: block;
    border: none;
    border-radius: 52px;
    font-weight: 500;
    margin: 20px auto 0 auto; }
