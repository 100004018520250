.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.footer {
  background-color: #2a4673;
  position: relative; }
  .footer .footer-container {
    padding: 30px 0; }
  .footer .footer-contact ul {
    list-style-type: none; }
    .footer .footer-contact ul li {
      margin-bottom: 15px; }
      .footer .footer-contact ul li a {
        text-decoration: none;
        display: flex;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px; }
        .footer .footer-contact ul li a span {
          display: inline-block; }
        .footer .footer-contact ul li a .icon {
          margin-right: 15px; }
        @media only screen and (max-width: 991px) {
          .footer .footer-contact ul li a {
            justify-content: center; } }
        @media only screen and (max-width: 480px) {
          .footer .footer-contact ul li a {
            font-size: 12px; } }
  .footer .footer-links ul {
    list-style-type: none; }
    .footer .footer-links ul li a {
      display: inline-block;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.8);
      font-size: 13px;
      font-weight: 500;
      line-height: 30px; }
  .footer .footer-links .footer-dot-ul .sustainability-footer a {
    color: #fff; }
    .footer .footer-links .footer-dot-ul .sustainability-footer a img {
      margin-right: 5px; }
  .footer .footer-links .footer-dot-ul li {
    position: relative; }
    .footer .footer-links .footer-dot-ul li:before {
      position: absolute;
      content: "";
      height: 6px;
      width: 6px;
      background-color: #45f6a6;
      border-radius: 50%;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%); }
      @media only screen and (max-width: 991px) {
        .footer .footer-links .footer-dot-ul li:before {
          display: none; } }
  .footer .bottom-footer {
    padding: 12px 0;
    background-color: #253f69; }
    .footer .bottom-footer .copy-right {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .footer .bottom-footer .copy-right .rights {
        color: #ffffff;
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 0; }
        .footer .bottom-footer .copy-right .rights a {
          color: #fff;
          display: inline-block;
          text-decoration: none; }
        @media only screen and (max-width: 991px) {
          .footer .bottom-footer .copy-right .rights {
            margin-bottom: 15px; } }
      @media only screen and (max-width: 991px) {
        .footer .bottom-footer .copy-right {
          text-align: center;
          flex-direction: column; } }
  @media only screen and (max-width: 991px) {
    .footer {
      text-align: center; } }

.body-login .footer::before,
.body-signup .footer::before {
  display: none; }
