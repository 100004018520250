.full-screen-loader {
  width: 100%;
  z-index: 1;
  min-height: 100vh;
  background: #FFB3B5; }
  .full-screen-loader .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100vh; }
    .full-screen-loader .spinner-container circle {
      stroke: white !important; }
  @media (max-width: 710px) {
    .full-screen-loader {
      min-height: 100vh; }
      .full-screen-loader .spinner-container {
        top: 50%; } }
