.sustainability-wrapper .container {
  padding: 40px 0; }
  .sustainability-wrapper .container .sustainability-home {
    text-align: center; }
    .sustainability-wrapper .container .sustainability-home h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px; }
    .sustainability-wrapper .container .sustainability-home > div {
      padding: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); }
      .sustainability-wrapper .container .sustainability-home > div .sustainability-image img {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px; }
      .sustainability-wrapper .container .sustainability-home > div p {
        font-size: 1em;
        line-height: 1.5;
        color: #333; }
        .sustainability-wrapper .container .sustainability-home > div p .sustainability-link {
          color: #0073e6;
          text-decoration: none; }
          .sustainability-wrapper .container .sustainability-home > div p .sustainability-link:hover {
            text-decoration: underline; }
